import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IRecall} from "../../../../store/reducers/recall/types";
import {Table} from "../../../../components/tables/Table/Table";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/rootReducer";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {MoreHoriz} from "@mui/icons-material";
import {deleteRecall, loadRecalls, setRecallPageData} from "../../../../store/reducers/recall/actions";
import {RecallSummary} from "../RecallSummary/RecallSummary";
import {TableRowDataType} from "../../../../types/types";
import {useModal} from "../../../../hooks/useModal/useModal";
import {useConfirm} from "../../../../hooks/useConfirm/useConfirm";
import {usePagination} from "../../../../hooks/usePaginations/usePaginations";
import {useException} from "../../../../hooks/useException/useException";
import {useSCs} from "../../../../hooks/useSCs/useSCs";

type TRecallTableProps = {
    onOpenModal: () => void;
    currentItem: IRecall|null;
    setCurrentItem: Dispatch<SetStateAction<IRecall|null>>;
}

const RecallTable: React.FC<React.PropsWithChildren<React.PropsWithChildren<TRecallTableProps>>> = ({onOpenModal, currentItem, setCurrentItem}) => {
    const {recalls, recallsCount} = useSelector((state: RootState) => state.recalls);
    const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);

    const dispatch = useDispatch();
    const showError = useException();
    const {askConfirm} = useConfirm();
    const {selectedSC} = useSCs();
    const {isOpen: isSummaryOpen, onOpen: onSummaryOpen, onClose: onSummaryClose} = useModal();
    const {changeRowsPerPage,changePage,pageIndex,pageSize} = usePagination(
        (s: RootState) => s.recalls.recallPageData,
        setRecallPageData
    );

    useEffect(() => {
        if (selectedSC) dispatch(loadRecalls(selectedSC.id))
    }, [selectedSC, pageIndex, pageSize])

    const onSummaryClick = (item: IRecall) => {
        setCurrentItem(item);
        onSummaryOpen();
    }

    const rowData: TableRowDataType<IRecall>[] = [
        {
            header: "Campaign #",
            val: el => el.recallCampaignNumber,
            width: 150,
        },
        {
            header: "Make",
            val: el => el.make?.name ?? ''
        },
        {
            header: "Model",
            val: el => el.model?.name ?? ''
        },
        {
            header: "From",
            val: el => el.yearRange?.from?.toString() ?? '',
        },
        {
            header: "To",
            val: el => el.yearRange?.to?.toString() ?? '',
        },
        {
            header: "Recall Component",
            val: el => el.recallComponent
        },
        {
            header: "Summary",
            val: el => <div
                style={{color: "#7898FF", cursor: 'pointer'}}
                onClick={() => onSummaryClick(el)}>
                Recall Summary
            </div>
        },
        {
            header: "Op Code",
            val: el => el.serviceRequest?.name ?? '',
            width: 150,
        },
        {
            header: "Part Lead Time (days)",
            val: el => el.partLeadDaysCount?.toString() ?? '',
        },
        {
            header: "Daily Parts",
            val: el => el.dailyPartsCount?.toString() ?? ''
        }
    ]

    const openMenu = (el: IRecall) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setCurrentItem(el);
        setAnchorEl(e.currentTarget);
    }

    const tableActions = (el: IRecall) => {
        return (
            <IconButton onClick={openMenu(el)} size="large">
                <MoreHoriz />
            </IconButton>
        );
    }

    const openEdit = () => {
        setAnchorEl(null);
        onOpenModal();
    }

    const handleRemove = async () => {
        if (!currentItem) {
            showError("Make is not chosen");
        } else {
            if (selectedSC) {
                try {
                    dispatch(deleteRecall(currentItem.id, selectedSC.id, showError))
                    setCurrentItem(null);
                } catch (e) {
                    showError(e);
                }
            }
        }
    }

    const askRemove = () => {
        setAnchorEl(null);
        if (!currentItem) {
            showError("Recall is not chosen");
        } else {
            const itemName = currentItem.recallCampaignNumber?.length < 25
                ? currentItem.recallCampaignNumber
                : currentItem.recallCampaignNumber.slice(0, 24).concat('...')
            askConfirm({
                isRemove: true,
                title: `Please confirm you want to remove Recall ${itemName}?`,
                onConfirm: handleRemove
            });
        }
    }

    return (
        <div>
            <Table<IRecall>
                data={recalls}
                index={"id"}
                rowData={rowData}
                actions={tableActions}
                rowsPerPage={pageSize}
                page={pageIndex}
                onChangePage={changePage}
                onChangeRowsPerPage={changeRowsPerPage}
                count={recallsCount}
                hidePagination={recallsCount < 11}
            />
            <Menu
                open={Boolean(anchorEl)}
                onClose={() => {setAnchorEl(null);}}
                anchorEl={anchorEl}
            >
                <MenuItem onClick={openEdit}>Edit</MenuItem>
                <MenuItem onClick={askRemove}>Remove</MenuItem>
            </Menu>
            {currentItem && <RecallSummary open={isSummaryOpen} onClose={onSummaryClose} summary={currentItem.recallSummary}/>}
        </div>
    );
};

export default RecallTable;